import React from 'react'
import {observer} from 'mobx-react'
import {Input} from 'storwork-components'
import _ from 'lodash'
import getStore from '../stores/DeliveriesStore'

export default props => <Delivery store={getStore()} id={props.match.params.id} />
const Delivery = observer(class _Delivery extends React.Component {
  StoreInput = Input(this.props.store)
  render () {
    const deliveryId = parseInt(this.props.id)
    if (!this.props.store.records.find(el => el.id === deliveryId)) return null
    const index = this.props.store.records.findIndex(el => el.id === deliveryId)
    return <div style={{padding: 20}}>
      <label class="label">Nazwa</label>
      {JSON.parse(this.props.store.records[index].json).name}
      <br /><br />
      <label class="label">Cele</label>
      {JSON.parse(this.props.store.records[index].json).targets.join(', ')}
    </div>
  }
})
