import { ListStore } from 'storwork-stores'

export default (filters) => {
  let staticFilters = {}
  const exploded = filters.split('&').map(filter => {
    staticFilters[filter.split('=')[0]] = filter.split('=')[1]
  })
  return new ListStore({
    type: 'contact',
    staticFilters,
  })
}
