import React from 'react'
import {observer} from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
import getStore from '../stores/DomainsStore'
import {SingleStore} from 'storwork-stores'
import {Input} from 'storwork-components'

const SingleElement = withRouter(props => {
  const {id, type, json} = props.element
  const name = JSON.parse(json).name
  return <li><Link to={'/domains/' + id} className={parseInt(props.match.params.id) === id ? 'is-active' : ''}>
    {name}
  </Link></li>
})

const Creator = observer(props => {
  const StoreInput = Input(props.store)
  return <div>
    <StoreInput className='input' placeholder='Nowa domena...' path='object.json' json='name' /><br /><br />
    <div className="button is-primary" onClick={() => {
      props.store.object.type = 'domain'
      props.store.sync()
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }}>Utwórz strone</div>
  </div>
})

export default () => <Domains store={getStore()} />
const Domains = observer(props => <div>
  <aside class="menu">
    <ul class="menu-list">
      {props.store.records.map(el => <SingleElement element={el} />)}
    </ul>
  </aside>
  <br />
  <hr />
  <Creator store={new SingleStore({
    type: 'generalobject',
    defaultObject: {
      type: '',
      json: '{}'
    },
    entities: [{
      path: '',
      alwaysSave: true,
      fields: ['json', 'type'],
      type: 'GeneralObject',
      multi: false
    }]
  })} />
</div>)
