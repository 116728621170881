import React from 'react'
import {observer} from 'mobx-react'
import {Input} from 'storwork-components'
import _ from 'lodash'
import getStore from '../stores/DomainsStore'
import getTemplatesStore from '../stores/TemplatesStore'

export default props => <Domain store={getStore()} templates={getTemplatesStore()} id={props.match.params.id} />
const Domain = observer(class _Domain extends React.Component {
  StoreInput = Input(this.props.store)
  render () {
    const domainId = parseInt(this.props.id)
    console.log(JSON.stringify(this.props.store.records));
    if (!this.props.store.records.find(el => el.id === domainId)) return null
    const index = this.props.store.records.findIndex(el => el.id === domainId)
    return <div style={{padding: 20}}>
      <label class="label">Nazwa</label>
      <this.StoreInput className='input' path={'records.' + index + '.json'} json='name' />
      <br />
      <br />
      <label class="label">Domena</label>
      <this.StoreInput className='input' path={'records.' + index + '.json'} json='domain' />
      <br />
      <br />
      <label class="label">Szablon</label>
      <div className="select">
        <this.StoreInput path={'records.' + index + '.json'} json='template_id' options={
            [{
              value: '0',
              label: '(brak)'
            }].concat(
              this.props.templates.records.map(el => ({
              value: el.id,
              label: JSON.parse(el.json).name
            })))
          } />
      </div>
      <br />
      <br />
      <button className='button is-primary' onClick={() => {
        this.props.store.sync(domainId)
      }}>zapisz</button>
  </div>
  }
})
