import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom'
import { StorworkInit } from 'storwork-stores'

import Templates from './views/Templates'
import Domains from './views/Domains'
import Deliveries from './views/Deliveries'

import Template from './views/Template'
import Domain from './views/Domain'
import Delivery from './views/Delivery'

const Routes = props => <React.Fragment>
  <nav className="navbar is-fixed" role="navigation" aria-label="main navigation" style={{border: '1px solid rgba(0,0,0,0.2)'}}>
    <div className="navbar-brand">
      <span class="navbar-item" style={{fontWeight: 'bold'}}>
        Marketing Automation
      </span>
      <Link className="navbar-item" to='/templates'>
        Szablony
      </Link>
      <Link className="navbar-item" to='/domains'>
        Domeny
      </Link>
      <Link className="navbar-item" to='/deliveries'>
        Dostawy
      </Link>
    </div>
  </nav>
  <div className='columns'>
    <div className='column is-3' style={{paddingLeft: 20}}>
      <Route path="/templates" exact component={Templates} />
      <Route path="/templates/:id" exact component={Templates} />
      <Route path="/domains" component={Domains} />
      <Route path="/deliveries" component={Deliveries} />
    </div>
    <div className='column' style={{position: 'relative'}}>
      <Route path="/templates/:id" component={Template} />
      <Route path="/domains/:id" component={Domain} />
      <Route path="/deliveries/:id" component={Delivery} />
    </div>
  </div>
</React.Fragment>

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<Routes />} />
  }
}

export default App;
