import React from 'react'
import {observer} from 'mobx-react'
import _ from 'lodash'
import getStore from '../stores/TemplatesStore'

export default props => <Template store={getStore()} id={props.match.params.id} />
const Template = observer(class _Template extends React.Component {
  state = {
    template: false
  }
  loadTemplate = () => {
    console.log(JSON.stringify(this.props.store));
    console.log(this.props.store.loaded)
    if (!this.props.store.loaded) return null
    const templateId = parseInt(this.props.id)
    const record = this.props.store.records.find(el => templateId === el.id)
    const content = JSON.parse(record.json)
    const grapes = content.grapes || '{}'
    const html = grapes.html
    const css = grapes.css

    localStorage.removeItem('gjs-html')
    localStorage.removeItem('gjs-components')
    localStorage.removeItem('gjs-css')
    localStorage.removeItem('gjs-styles')
    localStorage.removeItem('gjs-assets')

    const type = record.type == 'email_template' ? 'newsletter' : 'webpage'
    console.log('asd')
    window.grapesjs.init({
      container: '#gjs',
      plugins: ['gjs-preset-' + type, 'grapesjs-custom-code'],
      pluginsOpts: {
        'gjs-preset-webpage': {
          // options
        }
      },
      components: html,
      style: css
    })
  }
  componentWillReact () {
    this.loadTemplate()
  }
  componentDidMount () {
    this.loadTemplate()
  }
  save = () => {
    const html = localStorage.getItem('gjs-html')
    const css = localStorage.getItem('gjs-css')

    const templateId = parseInt(this.props.id)
    const recordIndex = this.props.store.records.findIndex(el => templateId === el.id)
    const content = JSON.parse(this.props.store.records[recordIndex].json)

    _.set(content, 'grapes.html', html)
    _.set(content, 'grapes.css', css)

    this.props.store.records[recordIndex].json = JSON.stringify(content)
    this.props.store.sync(templateId)
  }
  render () {
    return <div>
      {this.props.store.loaded && <span />}
      <div style={{position: 'relative'}}>
        <div style={{position:'absolute', top: 0, left: 0, right: 0, bottom: 0}} id='gjs' />
      </div>
      <span style={{position: 'absolute', top: '25px', left: '120px', zIndex: 100, color: 'white'}} onClick={this.save}>Save</span>
    </div>
  }
})

// import React from 'react'
// import baseAuth from 'services/baseAuth'
// import templateProvider from 'providers/template'
//
