import React from 'react'
import {observer} from 'mobx-react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import {SingleStore, FreeQuery} from 'storwork-stores'
import {Input} from 'storwork-components'
import getStore from '../stores/DeliveriesStore'
import getContacts from '../stores/ContactsStore'

const SingleElement = props => {
  const {id, type, json} = props.element
  const name = JSON.parse(json).name
  return <li><Link to={'/deliveries/' + id}>
    {name}
  </Link></li>
}

const Creator = observer(class _Creator extends React.Component {
  state = {
    savedFilters: []
  }
  componentDidMount () {
    FreeQuery(`{
      me {
        information(type:"saved_filter") {
          content
          name
        }
      }
    }`, {}, response => {
      this.setState({
        savedFilters: response.data.me.information
      })
    })
  }
  render () {
    const StoreInput = Input(this.props.store)
    return <div>
      <StoreInput placeholder='Nowa dostawa...' className='input' path='object.json' json='name' /> <br />
      <br /><div className='select'>
        <StoreInput path='object.json' json='type' options={[
          {value: '0', label: '(wybierz)'},
          {value: 'email', label: 'Email'},
          {value: 'sms', label: 'SMS'},
          {value: 'push', label: 'Powiadomienia push'},
        ]} />
    </div>
      <div className='select'>
        <StoreInput path='object.json' json='filter' options={
            [{value: '0', label: '(wybierz)'}].concat(this.state.savedFilters.map(el => ({
            label: el.name,
            value: JSON.parse(el.content).filter
          })))} />
      </div><br /><br />

      <div className='button is-primary' onClick={() => {
        const json = JSON.parse(this.props.store.object.json)
        const store = getContacts(json.filter)
        setTimeout(() => {
          this.props.store.object.type = 'delivery'
          json.targets = _.flatMap(store.records.map(el => el.emails.filter(data => data.status > 1))).map(el => el.email)
          this.props.store.object.json = JSON.stringify(json)
          this.props.store.sync()
        }, 3000)
        // this.props.store.sync().then(() => {
        //   window.location.reload()
        // })
      }}>Utwórz dostawę</div>
    </div>
  }
})

export default () => <Deliveries store={getStore()} />
const Deliveries = observer(props => <div>
  <aside class="menu">
    <ul class="menu-list">
      {props.store.records.map(el => <SingleElement element={el} />)}
    </ul>
  </aside>
  <br />
  <hr />
  <Creator store={new SingleStore({
    type: 'generalobject',
    defaultObject: {
      type: '',
      json: '{}'
    },
    entities: [{
      path: '',
      alwaysSave: true,
      fields: ['json', 'type'],
      type: 'GeneralObject',
      multi: false
    }]
  })} />
</div>)
