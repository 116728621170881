import { ListStore } from 'storwork-stores'

let store = null

export default () => {
  if (!store) {
    store = new ListStore({
      type: 'generalobject',
      staticFilters: {
        type: ['delivery']
      },
      entities: [{
        path: '',
        alwaysSave: true,
        fields: ['json', 'type'],
        type: 'GeneralObject',
        multi: false
      }]
    })
  }
  return store
}
